<template>
  <div class="flex flex-col">
    <div class="space-y-2">
      <div class="text-lg text-gray-800">
        Dengan bersyukur kita bahagia, dengan memberi kita merasa bermakna!
      </div>
      <div class="text-lg text-gray-800 italic">
        "....kita harus membantu orang-orang yang lemah dan harus mengingat perkataan Tuhan Yesus, sebab Ia sendiri telah mengatakan: Adalah lebih berbahagia memberi dari pada menerima."
      </div>
      <div class="text-lg text-gray-400">
        (Kisah Para Rasul 20:35)
      </div>
    </div>
    <div class="space-y-8 mt-8">
      <MaterialButton
        @click="redirectTo('Va')"
        class="offering-btn">Transfer ke rek BCA dan virtual account</MaterialButton>
      <MaterialButton
        @click="redirectTo('Qr')"
        class="offering-btn">Scan QR Code dengan dompet digital</MaterialButton>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const MaterialButton = defineAsyncComponent(() => import('@/components/common/MaterialButton.vue'))
export default {
  name: 'Offering',
  components: {
    MaterialButton
  },
  methods: {
    redirectTo (name) {
      this.$router.push({ name: `Offering${name}` })
    }
  }
}
</script>

<style lang="scss" scoped>
.offering-btn {
  @apply h-20 w-full;
}
</style>
